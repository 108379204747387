import {
	StorePostCartReq,
	StorePostCartsCartLineItemsItemReq,
	StorePostCartsCartLineItemsReq,
} from "models/Store.model";
import { medusaClient } from "utils/medusa-config";

const createCart = async (
	payload?: StorePostCartReq,
	customHeaders?: Record<string, any>
) => {
	let response =
		await medusaClient.carts
			.create(payload, customHeaders)
			.then((res) => res)
			.catch((err) => err);

	return response;
};

const retrieveCart = async (
	cartId: string,
	customHeaders?: Record<string, any>
) => {
	let response =
		await medusaClient.carts
			.retrieve(cartId, customHeaders)
			.then((res) => res)
			.catch((err) => {
				throw err;
			});

	return response;
};

const addLineItemToCart = async (
	cartId: string,
	payload: StorePostCartsCartLineItemsReq,
	customHeaders?: Record<string, any>
) => {
	let response =
		await medusaClient.carts.lineItems
			.create(
				cartId,
				payload,
				customHeaders
			)
			.then((res) => res)
			.catch((err) => {
				throw err;
			});

	return response;
};

const deleteLineItemFromCart = async (
	cartId: string,
	cartItemId: string,
	customHeaders?: Record<string, any>
) => {
	let response =
		await medusaClient.carts.lineItems
			.delete(
				cartId,
				cartItemId,
				customHeaders
			)
			.then((res) => res)
			.catch((err) => {
				throw err;
			});

	return response;
};

const updateLineItemFromCart = async (
	cartId: string,
	cartItemId: string,
	payload: StorePostCartsCartLineItemsItemReq,
	customHeaders?: Record<string, any>
) => {
	let response =
		await medusaClient.carts.lineItems
			.update(
				cartId,
				cartItemId,
				payload,
				customHeaders
			)
			.then((res) => res)
			.catch((err) => {
				throw err;
			});
	return response;
};

const deleteDiscountFromCart = async (
	cart_id: string,
	code: string,
	customHeaders?: Record<string, any>
) => {
	let response =
		await medusaClient.carts
			.deleteDiscount(
				cart_id,
				code,
				customHeaders
			)
			.then((res) => res)
			.catch((err) => {
				throw err;
			});
	return response;
};

const cartStoreAPI = {
	createCart,
	retrieveCart,
	addLineItemToCart,
	deleteLineItemFromCart,
	updateLineItemFromCart,
	deleteDiscountFromCart,
};

export default cartStoreAPI;
