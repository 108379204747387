"use client";

import React from "react";
import { BACKEND_URL } from "utils/config";
import { queryClient } from "utils/medusa-config";
import { PrerequisitiesProvider } from "contexts/PrerequisitiesContext";
import VirtualAnalytics from "components/analytics/VirtualAnalytics";
import ProgressBar from "components/progress";
import SnackbarProvider from "contexts/SnackbarProvider";
import { AuthDialogProvider } from "contexts/AuthDialogContext";
import { CartDropdownProvider } from "contexts/CartDropdownContext";
import { AccountProvider } from "contexts/AccountContext";
import SettingsProvider from "contexts/SettingContext";
import { CommerceProvider } from "contexts/CommerceContext";
import { StoreProvider } from "contexts/StoreContext";
import { CartProvider } from "contexts/CartContext";
import MuiTheme from "theme/MuiTheme";
import VirtualChatwootWidget from "components/chatwoot/VirtualChatwootWidget";

interface AppProvidersProps {
	children: React.ReactNode;
}

const AppProviders: React.FC<
	AppProvidersProps
> = ({ children }) => {
	return (
		<MuiTheme>
			<PrerequisitiesProvider>
				<CommerceProvider
					baseUrl={BACKEND_URL}
					queryClientProviderProps={{
						client: queryClient,
					}}>
					<SnackbarProvider>
						<CartDropdownProvider>
							<CartProvider>
								<SettingsProvider>
									<AuthDialogProvider>
										<AccountProvider>
											<StoreProvider>
												<ProgressBar />
												<VirtualAnalytics />
												{children}
												<VirtualChatwootWidget />
											</StoreProvider>
										</AccountProvider>
									</AuthDialogProvider>
								</SettingsProvider>
							</CartProvider>
						</CartDropdownProvider>
					</SnackbarProvider>
				</CommerceProvider>
			</PrerequisitiesProvider>
		</MuiTheme>
	);
};

export default AppProviders;
