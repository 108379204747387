import { useCart } from "contexts/CartContext";
import { useCreateLineItem } from "hooks/useCreateLineItem";
import { useDeleteLineItem } from "hooks/useDeleteLineItem";
import { useUpdateLineItem } from "hooks/useUpdateLineItem";
import { Region } from "models/Region.model";
import React, {
	useEffect,
	useState,
	useRef,
} from "react";
import cartStoreApi from "__api__/storeAPI/cart";
import handleError from "utils/handleError";
import { useCartDropdown } from "./CartDropdownContext";
import { useRouter } from "next/navigation";
import { useAccount } from "./AccountContext";
import { Cart } from "models/Cart.model";

interface VariantInfoProps {
	variantId: string;
	quantity: number;
}

interface LineInfoProps {
	lineId: string;
	quantity: number;
}

interface StoreContext {
	cart:
		| Cart
		| Omit<
				Cart,
				| "refundable_total"
				| "refundedTotal"
		  >
		| undefined;
	countryCode: string | undefined;
	setRegion: (
		regionId: string,
		countryCode: string
	) => void;
	setCart: (
		cart:
			| Cart
			| Omit<
					Cart,
					| "refundable_total"
					| "refundedTotal"
			  >
			| undefined
	) => void;
	retrievingCart: boolean;
	isCartLoading: boolean;
	resetCart: () => void;
	addItemToCart: (
		item: VariantInfoProps
	) => void;
	updateItemInCart: (
		item: LineInfoProps
	) => void;
	deleteItemFromCart: (
		lineId: string
	) => void;
	isAddingItemToCart: boolean;
	isAddingItemToCartError:
		| boolean
		| null;
	isAddingItemToCartSuccess:
		| boolean
		| null;
	isRemovingItemFromCart: boolean;
	isUpdatingItemInCart: boolean;
	setShippingMethodInCart: (
		soId: string
	) => void;
	isSettingShippingMethodInCart: boolean;
	completingCart: boolean;
	completeCart: () => void;
}

const StoreContext =
	React.createContext<StoreContext | null>(
		null
	);

export const useStore = () => {
	const context = React.useContext(
		StoreContext
	);
	if (context === null) {
		throw new Error(
			"useStore must be used within a StoreProvider"
		);
	}
	return context;
};

interface StoreProps {
	children: React.ReactNode;
}

const IS_SERVER =
	typeof window === "undefined";
const CART_KEY = "medusa_cart_id";
const REGION_KEY = "medusa_region";

export const StoreProvider = ({
	children,
}: StoreProps) => {
	const router = useRouter();
	const {
		cart,
		setCart,
		createCart,
		updateCart,
		addShippingMethod: {
			mutateAsync: setShippingMethod,
			isLoading:
				isSettingShippingMethodInCart,
		},
		completeCheckout: {
			mutateAsync: completeCart,
			isLoading: completingCart,
		},
	} = useCart();
	const [countryCode, setCountryCode] =
		useState<string | undefined>(
			undefined
		);

	const [
		retrievingCart,
		setRetrievingCart,
	] = useState(false);

	const {
		customer,
		retrievingCustomer,
	} = useAccount();

	const isFirstRender = useRef(true);
	const randomReferenceForTab = useRef(
		Math.random()
	);

	const cartShippinMethodId =
		cart?.shipping_methods?.[0]?.id;
	const cartPaymentMethodType = (
		cart?.context?.payment_method as any
	)?.type as string;

	const hasRetrivedCartAfterAMessage =
		useRef(false);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}
		if (!cart?.id) return;
		if (
			hasRetrivedCartAfterAMessage.current
		) {
			hasRetrivedCartAfterAMessage.current =
				false;
			return;
		}

		const channel =
			new BroadcastChannel(
				"cart_update"
			);

		channel.postMessage({
			type: "cart_retrieve",
			reference:
				randomReferenceForTab.current,
		});
		return () => channel.close();
	}, [
		cart?.id,
		cart?.region?.id,
		cartShippinMethodId,
		cart?.shipping_address?.updated_at,
		cart?.shipping_address?.created_at,
		cart?.billing_address?.updated_at,
		cart?.billing_address?.created_at,
		cart?.payment_session?.provider_id,
		cartPaymentMethodType,
		cart?.discounts?.length,
		cart?.total,
		cart?.completed_at,
	]);

	useEffect(() => {
		const retrieveCart = async () => {
			setRetrievingCart(true);
			const cartId = getCart();
			if (!cartId) return;
			await cartStoreApi
				.retrieveCart(cartId)
				.then(({ cart }) => {
					setCart(cart);
					hasRetrivedCartAfterAMessage.current =
						true;
				})
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				.catch(async (_) => {
					window.location.reload();
				})
				.finally(() => {
					setRetrievingCart(false);
				});
		};

		const channel =
			new BroadcastChannel(
				"cart_update"
			);
		channel.onmessage = (message) => {
			if (
				message.data.type ===
					"cart_retrieve" &&
				message.data.reference !==
					randomReferenceForTab.current
			) {
				retrieveCart();
			}
		};

		return () => channel.close();
	}, [router, setCart]);

	const { timedOpen } =
		useCartDropdown();
	const {
		mutate: addLineItem,
		isLoading: isAddingItemToCart,
		isError: isAddingItemToCartError,
		isSuccess:
			isAddingItemToCartSuccess,
	} = useCreateLineItem(cart?.id!);
	const {
		mutate: removeLineItem,
		isLoading: isRemovingItemFromCart,
	} = useDeleteLineItem(cart?.id!);
	const {
		mutate: adjustLineItem,
		isLoading: isUpdatingItemInCart,
	} = useUpdateLineItem(cart?.id!);

	const storeRegion = (
		regionId: string,
		countryCode: string
	) => {
		if (!IS_SERVER) {
			localStorage.setItem(
				REGION_KEY,
				JSON.stringify({
					regionId,
					countryCode,
				})
			);

			setCountryCode(countryCode);
		}
	};

	useEffect(() => {
		if (!IS_SERVER) {
			const storedRegion =
				localStorage.getItem(
					REGION_KEY
				);
			if (storedRegion) {
				const { countryCode } =
					JSON.parse(storedRegion);
				setCountryCode(countryCode);
			}
		}
	}, []);

	const getRegion = () => {
		if (!IS_SERVER) {
			const region =
				localStorage.getItem(
					REGION_KEY
				);
			if (region) {
				return JSON.parse(region) as {
					regionId: string;
					countryCode: string;
				};
			}
		}
		return null;
	};

	const setRegion = async (
		regionId: string,
		countryCode: string
	) => {
		await updateCart.mutateAsync(
			{
				region_id: regionId,
			},
			{
				onSuccess: ({ cart }) => {
					setCart(cart);
					storeCart(cart?.id);
					storeRegion(
						regionId,
						countryCode
					);
				},
				onError: (error) => {
					handleError({
						error,
						notifyError: false,
					});
				},
			}
		);
	};

	const ensureRegion = (
		region: Region,
		countryCode?: string | null
	) => {
		if (!IS_SERVER) {
			const {
				regionId,
				countryCode: defaultCountryCode,
			} = getRegion() || {
				regionId: region?.id ?? "",
				countryCode:
					region.countries[0].iso_2,
			};

			const finalCountryCode =
				countryCode ||
				defaultCountryCode;

			if (regionId !== region.id) {
				setRegion(
					region.id,
					finalCountryCode
				);
			}

			storeRegion(
				region.id,
				finalCountryCode
			);
			setCountryCode(finalCountryCode);
		}
	};

	const storeCart = (id: string) => {
		if (!IS_SERVER) {
			localStorage.setItem(
				CART_KEY,
				id
			);
		}
	};

	const getCart = () => {
		if (!IS_SERVER) {
			return localStorage.getItem(
				CART_KEY
			);
		}
		return null;
	};

	const deleteCart = () => {
		if (!IS_SERVER) {
			localStorage.removeItem(CART_KEY);
		}
	};

	const deleteRegion = () => {
		if (!IS_SERVER) {
			localStorage.removeItem(
				REGION_KEY
			);
		}
	};

	const createNewCart = async (
		regionId?: string
	) => {
		await createCart.mutateAsync(
			{ region_id: regionId },
			{
				onSuccess: ({ cart }) => {
					setCart(cart);
					storeCart(cart?.id);
					ensureRegion(
						cart?.region,
						cart?.shipping_address
							?.country_code
					);
				},
				onError: (error) => {
					handleError({
						error,
						notifyError: false,
					});
				},
			}
		);
	};

	const resetCart = () => {
		deleteCart();

		const savedRegion = getRegion();

		createCart.mutate(
			{
				region_id:
					savedRegion?.regionId,
			},
			{
				onSuccess: ({ cart }) => {
					setCart(cart);
					storeCart(cart?.id);
					ensureRegion(
						cart?.region,
						cart?.shipping_address
							?.country_code
					);
				},
				onError: (error) => {
					// rerieve cart
					handleError({
						error,
						notifyError: false,
					});
				},
			}
		);
	};
	const ensureCart = async () => {
		setRetrievingCart(true);

		const browserCartId = getCart();

		const region = getRegion();

		if (browserCartId) {
			const cartRes = await cartStoreApi
				.retrieveCart(browserCartId)
				.then(({ cart }) => {
					return cart;
				})
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				.catch(async (_) => {
					return null;
				})
				.finally(() => {
					setRetrievingCart(false);
				});

			if (
				!cartRes ||
				cartRes.completed_at
			) {
				deleteCart();
				deleteRegion();
				await createNewCart().finally(
					() => {
						setRetrievingCart(false);
					}
				);
				return;
			}

			if (
				cartRes.id !== browserCartId
			) {
				storeCart(cartRes.id);
			}

			setCart(cartRes);
			ensureRegion(cartRes.region);
		} else {
			await createNewCart(
				region?.regionId
			).finally(() => {
				setRetrievingCart(false);
			});
		}
	};

	useEffect(() => {
		if (retrievingCustomer) return;
		if (isCartLoading) return;

		if (!IS_SERVER) {
			if (
				!cart?.id ||
				cart?.customer_id !==
					customer?.id
			) {
				ensureCart();
				return;
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		customer?.id,
		retrievingCustomer,
	]);

	const setShippingMethodInCart =
		async (soId: string) => {
			await setShippingMethod(
				{
					option_id: soId,
				},
				{
					onSuccess: ({ cart }) => {
						setCart(cart);
						storeCart(cart?.id);
					},
					onError: (error) => {
						ensureCart();
						handleError({
							error,
							notifyError: false,
						});
					},
				}
			);
		};

	const addItemToCart = ({
		variantId,
		quantity,
	}: {
		variantId: string;
		quantity: number;
	}) => {
		addLineItem(
			{
				variant_id: variantId,
				quantity: quantity,
			},
			{
				onSuccess: ({ cart }) => {
					setCart(cart);
					storeCart(cart?.id);
					timedOpen();
				},
				onError: (error) => {
					handleError({
						error,
						notifyError: true,
					});
				},
			}
		);
	};

	const deleteItemFromCart = (
		lineId: string
	) => {
		removeLineItem(
			{
				lineId,
			},
			{
				onSuccess: ({ cart }) => {
					setCart(cart);
					storeCart(cart?.id);
				},
				onError: (error) => {
					handleError({
						error,
						notifyError: true,
					});
				},
			}
		);
	};

	const updateItemInCart = ({
		lineId,
		quantity,
	}: {
		lineId: string;
		quantity: number;
	}) => {
		adjustLineItem(
			{
				lineId,
				quantity,
			},
			{
				onSuccess: ({ cart }) => {
					setCart(cart);
					storeCart(cart?.id);
				},
				onError: (error) => {
					handleError({
						error,
						notifyError: true,
					});
				},
			}
		);
	};

	const isCartLoading =
		retrievingCart ||
		isSettingShippingMethodInCart ||
		completingCart ||
		isAddingItemToCart ||
		isRemovingItemFromCart ||
		isUpdatingItemInCart;

	return (
		<StoreContext.Provider
			value={{
				cart,
				countryCode,
				setRegion,
				setCart,
				isCartLoading,
				retrievingCart,
				resetCart,
				addItemToCart,
				isAddingItemToCart,
				isAddingItemToCartError,
				isAddingItemToCartSuccess,
				deleteItemFromCart,
				isRemovingItemFromCart,
				updateItemInCart,
				isUpdatingItemInCart,
				setShippingMethodInCart,
				isSettingShippingMethodInCart,
				completeCart,
				completingCart,
			}}>
			{children}
		</StoreContext.Provider>
	);
};
