import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import { SpeedInsights as VercelSpeedInsights } from "@vercel/speed-insights/next";
import { useAccount } from "contexts/AccountContext";
import { useCart } from "contexts/CartContext";
import useSettings from "hooks/useSettings";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { cdpClient } from "utils/analytics";
import { debounce } from "utils/debounce";

export default function VirtualAnalytics() {
	const pathname = usePathname();
	const { cart } = useCart();
	const {
		customer,
		retrievingCustomer,
	} = useAccount();

	useEffect(() => {
		if (!cdpClient) return;
		if (!cart?.id) return;

		const debounceTracking = debounce(
			() => {
				cdpClient?.setAnonymousId(
					cart.id
				);
				cdpClient?.page();
			},
			1000
		);

		debounceTracking();
	}, [pathname, cart?.id]);

	useEffect(() => {
		if (!cdpClient) return;
		if (!cart?.id) return;

		const debounceTracking = debounce(
			() => {
				if (retrievingCustomer) return;
				if (!customer?.has_account) {
					if (!cart?.email) return;
					cdpClient?.identify(
						cart?.customer_id ||
							cart?.id,
						{
							email: cart?.email,
						}
					);
					return;
				}

				cdpClient?.identify(
					customer?.id,
					{
						email: customer?.email,
						firstName:
							customer?.first_name,
						lastName:
							customer?.last_name,
						phone: customer?.phone,
					}
				);
			},
			1000
		);

		debounceTracking();
	}, [
		cart?.id,
		cart?.customer_id,
		cart?.email,
		cart?.billing_address,
		cart?.shipping_address,
		retrievingCustomer,
		customer,
	]);
	const {
		settings: {
			privacy_consent: {
				analytics: analyticsConsent,
				essential: essentialConsent,
			},
		},
	} = useSettings();

	return (
		<>
			{essentialConsent && (
				<VercelSpeedInsights />
			)}
			{analyticsConsent && (
				<VercelAnalytics />
			)}
		</>
	);
}
