import { ThemeOptions } from "@mui/material";
import { components } from "./components";
import {
	primary,
	themeColors,
} from "./themeColors";
import { typography } from "./typography";

const breakpoints = {
	values: {
		xs: 0,
		sm: 600,
		md: 960,
		lg: 1280,
		xl: 1920,
	},
};

const themeOptions = () => {
	const options: ThemeOptions = {
		typography,
		breakpoints,
		components,
		palette: {
			primary: {
				...primary,
				light: primary[100],
			},
			...themeColors,
		},
	};

	return options;
};

export default themeOptions;
