import crypto from "crypto";

export const chatwootBaseUrl =
	process.env
		.NEXT_PUBLIC_CHATWOOT_API_URL ||
	"https://support.noomidoo.com";

export const chatwootWebsiteToken =
	process.env.CHATWOOT_WEBSITE_TOKEN ||
	"9epxTAhMgZBQMrusFBpkjCK6";

export const chatwootWidgetToken =
	process.env.CHATWOOT_WIDGET_TOKEN ||
	"6bhBwjWyX7BwDyqn4p4ZNDwp";

export async function getChatwootHashFromServer(
	identifier: string
) {
	const hash = crypto
		.createHmac(
			"sha256",
			chatwootWidgetToken
		)
		.update(identifier)
		.digest("hex");
	return hash;
}
