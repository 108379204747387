import {
	SvgIcon,
	SvgIconProps,
} from "@mui/material";

const LiveChat = (
	props: SvgIconProps
) => {
	return (
		<SvgIcon
			{...props}
			viewBox='0 0 44 44'>
			<path d='M3.66664 22C3.66664 11.8745 11.8745 3.66663 22 3.66663C32.1255 3.66663 40.3333 11.8745 40.3333 22C40.3333 32.1255 32.1255 40.3333 22 40.3333H3.66664L9.03647 34.9635C7.33154 33.2629 5.97946 31.2423 5.05792 29.0176C4.13639 26.7929 3.66357 24.408 3.66664 22ZM12.518 36.6666H22C24.9008 36.6666 27.7364 35.8064 30.1483 34.1948C32.5603 32.5833 34.4401 30.2926 35.5502 27.6126C36.6603 24.9327 36.9507 21.9837 36.3848 19.1386C35.8189 16.2936 34.422 13.6802 32.3709 11.6291C30.3197 9.57789 27.7064 8.18103 24.8613 7.61511C22.0162 7.04919 19.0673 7.33964 16.3873 8.44973C13.7073 9.55981 11.4167 11.4397 9.80509 13.8516C8.19349 16.2635 7.33331 19.0992 7.33331 22C7.33331 25.9453 8.89347 29.6358 11.6288 32.3711L14.2211 34.9635L12.518 36.6666ZM14.6666 23.8333H29.3333C29.3333 25.7782 28.5607 27.6435 27.1854 29.0187C25.8102 30.394 23.9449 31.1666 22 31.1666C20.0551 31.1666 18.1898 30.394 16.8145 29.0187C15.4393 27.6435 14.6666 25.7782 14.6666 23.8333Z' />
		</SvgIcon>
	);
};

export default LiveChat;
