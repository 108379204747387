import {
	Components,
	Theme,
} from "@mui/material";
import {
	black,
	grey,
} from "./themeColors";

import { typography } from "./typography";

// ========================================================
declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		black: true;
		white: true;
		paste: true;
		marron: true;
	}

	interface ButtonPropsSizeOverrides {
		normal: true;
	}
}

declare module "@mui/material/Chip" {
	interface ChipPropsColorOverrides {
		black: true;
		white: true;
		paste: true;
		marron: true;
	}
}

// =========================================================

export const components: Components = {
	MuiCssBaseline: {
		styleOverrides: (theme: Theme) => ({
			html: {
				scrollBehavior: "smooth",
			},
			p: { lineHeight: 1.75 },
			button: {
				fontSize: 14,
				fontFamily:
					typography.fontFamily,
			},
			".MuiRating-sizeSmall": {
				fontSize: "20px",
			},
			a: {
				textDecoration: "none",
				color: "inherit",
			},
			ul: {
				margin: 0,
				padding: 0,
				listStyle: "none",
			},
			"#nprogress .bar": {
				overflow: "hidden",
				height: "3px !important",
				zIndex: "99999999 !important",
				background: `${theme.palette.primary.main} !important`,
			},
		}),
	},
	MuiInputLabel: {
		styleOverrides: {
			root: { zIndex: 0 },
		},
	},
	MuiDialog: {
		styleOverrides: {
			paper: { borderRadius: 8 },
		},
	},
	MuiCard: {
		styleOverrides: {
			root: { borderRadius: "8px" },
		},
	},
	MuiPagination: {
		defaultProps: {
			variant: "outlined",
			color: "primary",
		},
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				paddingTop: 8,
				paddingBottom: 8,
			},
		},
	},
	MuiSvgIcon: {
		styleOverrides: {
			root: {
				"& .secondary": {
					opacity: 0.4,
				},
			},
		},
	},
	MuiTextField: {
		defaultProps: {
			size: "small",
			variant: "outlined",
		},
		styleOverrides: {
			root: ({ ownerState }) => ({
				...(ownerState.color ===
					"info" && {
					"& .MuiOutlinedInput-root": {
						borderRadius: "8px",
						fontWeight: 600,
					},
					"& .MuiOutlinedInput-notchedOutline":
						{ borderColor: grey[300] },
				}),
			}),
		},
	},

	MuiButton: {
		styleOverrides: {
			root: ({ ownerState }) => ({
				minWidth: 0,
				minHeight: 0,
				fontWeight: 600,
				textTransform: "none",
				...(ownerState.variant ===
					"text" && {
					// underline text
					textDecoration: "none",
				}),
				...(ownerState.color ===
					"info" && {
					borderRadius: "8px",
				}),
				...(ownerState.color ===
					"black" &&
					ownerState.variant ===
						"contained" && {
						color: "white",
						backgroundColor: black.main,
						borderRadius: 0,
						transition: "all 0.3s",
						":hover": {
							backgroundColor:
								black.main,
							color: "white",
						},
					}),
				...(ownerState.color ===
					"black" &&
					ownerState.variant ===
						"outlined" && {
						color: black.main,
						borderRadius: "3px",
						transition: "all 0.3s",
						":hover": {
							backgroundColor:
								black.main,
							color: "white",
						},
					}),
				...(ownerState.color ===
					"white" && {
					color: black.main,
					borderRadius: 0,
					backgroundColor: "white",
					transition: "all 0.3s",
					":hover": {
						backgroundColor: "white",
					},
				}),
				...(ownerState.color ===
					"white" &&
					ownerState.variant ===
						"outlined" && {
						color: "white",
						borderRadius: "3px",
						backgroundColor: "#000",
						transition: "all 0.3s",
						":hover": {
							backgroundColor: "white",
							color: "#000",
						},
					}),
			}),
			sizeLarge: {
				padding: ".6rem 2.5rem",
			},
		},
		defaultProps: { color: "inherit" },
	},
	MuiChip: {
		defaultProps: { color: "primary" },
		styleOverrides: {
			labelSmall: { paddingInline: 12 },
		},
	},
};
